import {
  Box,
  Button,
  Text,
  Flex,
  color,
  Divider,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ArrowBackIcon } from '@chakra-ui/icons';
import NotifyPopup from '../NotifyPopup';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { HomeIcon } from '../../../assets/icons/home';
import { CartIcon } from '../../../assets/icons/mobileCart';
import { ScanIcon } from '../../../assets/icons/scan';
import { SettingsIcon } from '../../../assets/icons/settings';

const Sidebar: FC = () => {
  const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
  const { state: userState } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const mobileHeight = '69px';

  const location = useLocation();
  useEffect(() => {}, [location.pathname]);
  useEffect(() => {}, [cartState.notifyText]);

  if (userState.operator === null) {
    return null;
  }

  const changeSearchMode = (searchMode: number) => {
    cartDispatch({ type: 'CHANGE_SEARCHMODE', searchMode });
    navigate('/');
  };

  const doSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const doBrowseReceipts = () => {
    console.log('EXECUTE', 'BrowseReceipts');
    window.open(
      `${process.env.REACT_APP_POSMANAGER_URI}/Applications/ShopReceipt?shop=${userState.posDetails?.shopCode}`
    );
  };

  if (isMobile) {
    return (
      <></>

      /* const colorSelected = '#FEDCA9';
    const colorUnselected = '#FFFFFF';
    const currentPath = location.pathname;
    const colorHome = currentPath === '/' ? colorSelected : colorUnselected;
    const colorCart = currentPath === '/cart' ? colorSelected : colorUnselected;
    const colorScan = currentPath === '/scan' ? colorSelected : colorUnselected;
    const colorSettings =
      currentPath === '/settings' ? colorSelected : colorUnselected;
    const popupTop = `calc(100% - ${mobileHeight} - 60px)`;

    return (
      <>
        <NotifyPopup top={popupTop} height="60px" width="100%" />
        <Flex
          w="100%"
          flexDir="row"
          justifyContent="center"
          bgColor="brand.primary"
          px="2"
          flex="0"
          boxShadow="md"
          p="0px 50px 0px 50px"
        >
          <Flex
            h={mobileHeight}
            flex="1"
            justifyContent="space-evenly"
            alignContent="center"
          >
            <Button variant="link" mx="5" as={RouterLink} to="/">
              <HomeIcon color={colorHome} width="28px" height="28px" />
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/cart">
              <CartIcon color={colorCart} width="28px" height="28px" />
              <Text
                style={{ fontSize: '18px', fontWeight: 'bold' }}
                color={colorCart}
              >{`${cartState.cart.getProductCount()}`}</Text>
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/scan">
              <ScanIcon color={colorScan} width="28px" height="28px" />
            </Button>
            <Button variant="link" mx="5" as={RouterLink} to="/settings">
              <SettingsIcon color={colorSettings} width="28px" height="28px" />
            </Button>
          </Flex>
        </Flex>
      </> */
    );
  }
  return (
    <>
      <Flex
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        flexDir="row"
        // justifyContent="center"
        bgColor="brand.primary"
        // px="2"
        // flex="0"
        // boxShadow="md"
        // pr="250px"
      >
        <Flex flex="2" color="brand.secondary" direction="column">
          <Text fontSize="lg" padding="1em">
            CGI PosManager
          </Text>
          <Text fontSize="xs" padding="1.5em">
            {`${userState.shopName} (${userState.posDetails?.posCode})`} <br />
            {userState.operator}
          </Text>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            onClick={() => changeSearchMode(1)}
          >
            {t('appHeader.pos')}
          </Button>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            onClick={() => changeSearchMode(3)}
          >
            {t('appHeader.products')}
          </Button>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            onClick={() => changeSearchMode(2)}
          >
            {t('appHeader.customers')}
          </Button>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            onClick={() => doBrowseReceipts()}
          >
            {t('appHeader.browseReceipts')}
          </Button>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            as={RouterLink}
            to={cartState.cart.products?.length > 0 ? '' : '/cashdraw'}
            isDisabled={cartState.cart.products?.length > 0}
          >
            {t('appHeader.cashdraw')}
          </Button>
          <Divider />
          {/*           <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            as={RouterLink}
            to={'/desktop'}
          >
            {t('Myyjän työpöytä')}
          </Button>
          <Divider /> */}
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            as={RouterLink}
            to={cartState.cart.products?.length > 0 ? '' : '/settings'}
            isDisabled={cartState.cart.products?.length > 0}
          >
            {t('appHeader.settings')}
          </Button>
          <Divider />
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghosted"
            justifyContent="flex-start"
            onClick={() => doSignOut()}
          >
            {t('appHeader.signOut')}
          </Button>
          <Divider />
        </Flex>
      </Flex>
    </>
  );
};

export default Sidebar;
